<template>
  <div />
</template>

<script>
export default {
  name: 'NowwLogout',

  created () {
    this.nowwLogout()
  },

  methods: {
    async nowwLogout () {
      await this.$store.dispatch('auth/logOut')
      this.$router.push({ name: 'AppLogin' })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
